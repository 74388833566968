<script lang="ts">
	import { fade } from 'svelte/transition'
	import ChevronLeft from './icons/ChevronLeft.svelte'
	import ChevronRight from './icons/ChevronRight.svelte'
	import config from 'src/config'
	import { onMount } from 'svelte'

	let items = config.ribbonMessages

	let idx = 0
	const scrollSeconds = 10
	let interval: NodeJS.Timeout

	onMount(() => {
		initInterval()
	})

	function initInterval() {
		deleteInterval()

		interval = setInterval(() => {
			// if (!paused) {
			nextMessage()
			// }
		}, scrollSeconds * 1000)
	}

	function deleteInterval() {
		clearInterval(interval)
	}

	function nextMessage() {
		// items.push(items.shift())
		// items = items
		if (idx == items.length - 1) {
			idx = 0
		} else {
			idx++
		}
		initInterval()
	}

	function previousMessage() {
		// items.unshift(items.pop())
		// items = items
		if (idx == 0) {
			idx = items.length - 1
		} else {
			idx--
		}
		initInterval()
	}
</script>

<div
	id="ribbon"
	class="border-light flex h-[48px] w-full items-center justify-between border-b bg-black px-2 py-2 text-center text-[12px] md:text-[14px]"
>
	<div class="container mx-auto flex w-full items-center justify-between px-6">
		<button
			aria-label="Previous Announcement"
			class="py-2 pr-2"
			class:hidden={items.length == 1}
			on:click={previousMessage}
		>
			<ChevronLeft />
		</button>
		{#key idx}
			<p class="text-balance flex-1" in:fade style="text-wrap: balance;">
				{@html items[idx]}
			</p>
		{/key}
		<button
			aria-label="Next Announcement"
			class="py-2 pl-2"
			class:hidden={items.length == 1}
			on:click={nextMessage}
		>
			<ChevronRight />
		</button>

		<!-- <div class="flex w-32 items-center space-x-3">
			<button class="hover:glass py-2 pr-2" on:click={previousMessage}>
				<ChevronLeft />
			</button>
			<button class="hover:glass p-2" on:click={nextMessage}>
				<ChevronRight />
			</button>
		</div>
		{#key items[0]}
			<p class="text-balance flex-1" in:fade style="text-wrap: balance;">
				{items[0]}
			</p>
		{/key}
		<div class="w-32"></div> -->
	</div>
</div>
